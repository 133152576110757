import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UtilsService } from 'src/app/services/utils.service';
import gtag from 'ga-gtag';
import { ENV } from 'src/environments/environment';

@Component({
  selector: 'app-confirmar-anular-reserva',
  templateUrl: './confirmar-anular-reserva.component.html',
  styleUrls: ['./confirmar-anular-reserva.component.scss']
})
export class ConfirmarAnularReservaComponent implements OnInit {

  @Output() public dialogEvent: EventEmitter<any> = new EventEmitter();
  public cita: any;

  constructor(
    public utils: UtilsService,
    public dialogRef: MatDialogRef<ConfirmarAnularReservaComponent>,
    @Inject(MAT_DIALOG_DATA) public citaData: { data: any },

  ) {
    this.cita = citaData;
  }

  ngOnInit() {
  }

  actionConfirmarAnular(action) {

    /******* GTM PUSH *******/
    const evento = this.cita.fromReserva ? 'Anular_reserva_directa' : 'Anular_reserva_flujo'
    try {
      gtag('event', evento, {
        event: evento,
        environment: ENV.name,
        userLogin: false,
        confirmacion_exito: "SI",
        confirmacion_completado: "SI",
        dimension_especialidad: this.cita.nombreEspecialidad,
        dimension_profesional_p1_p2: this.cita.nombreProfesional,
        dimension_centro: this.cita.Centro ? this.cita.Centro.Nombre : "",
        dimension_servicio: this.cita.nombreServicio,
      });
    } catch (err) {
      console.log("GTM " + evento + " ERROR", err);
    }
    /***********************/

    this.dialogEvent.emit(action);
    this.dialogRef.close();
  }

}
