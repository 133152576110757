export const MENSAJES_CALENDARIO = [
    {
        "idAreas": [
            "aafc27df-882e-413b-bdcd-a96e00ddbc15", // Consultas
            "97bd5208-60d1-4d6c-9d1e-a96e00ddbc15", // Procedimientos y Examenes
            "7a9d5f62-8899-4a5c-a28a-a9730138ecdd", // LKinesiologia
            "99880dee-c605-4c01-a226-a97301252d72" // Odontología
        ],
        mensaje: `
        <p class="hil">No encontramos citas para la búsqueda que hiciste.</p>
        <p class="shil">Te recomendamos:</p>
        <ul>
            <li><b>Ampliar la búsqueda cambiando los parámetros.</b> 
                Ejemplo: buscar por especialidad en vez de profesional; 
                por un servicio general en vez de un servicio específico;
                o buscar en otro o todos los centros de atención.
            </li>
            <li><b>Revisar que no tengas una reserva similar previamente agendada.</b> 
                Deberás anularla antes de reservar una nueva. Para hacerlo busca en tu correo 
                electrónico un email de Reservas UCCHRISTUS (no olvides revisar el spam), 
                o ve al Portal Paciente.
            </li>
            <li>
                <b>Revisar que el paciente calce con la búsqueda.</b> 
                Ejemplo: adulto buscando reservar en pediatría no muestra cupos.
            </li>
        </ul>`
    },
    {
        "idAreas": [
            "RIS_IMAGENES", // Radiología e Imágenes
        ],
        mensaje: `
        <p class="hil">No encontramos citas para la búsqueda que hiciste.</p>
        <p class="shil">Te recomendamos:</p>
        <ul>
            <li><b>Ampliar la búsqueda cambiando los parámetros.</b>
                Ejemplo: buscar en otro o todos los centros de atención.
            </li>
        </ul>`
    },
    {
        "idAreas": [
            "86399d7e-7bd3-4bed-b18a-aca100fc732c", // Laboratorio clinico
             
        ],
        mensaje: `
        <p class="hil">No encontramos citas para la búsqueda que hiciste.</p>
        <p class="shil">Te recomendamos:</p>
        <ul>
            <li><b>Ampliar la búsqueda cambiando los parámetros.</b> 
                Ejemplo: buscar en otro o todos los centros de atención.
            </li>
            <li>
            	<b>Para PCR Covid</b> se publican cupos nuevos constantemente 
                para fechas a futuro, te sugerimos buscar de nuevo en una hora más.
               
            </li>

        </ul>`
    },
    {
        "idAreas": [
            "3f897659-cbf1-429c-8250-acc30103787b", // Vacunatorio
        ],
        mensaje: `
        <p class="hil">No encontramos citas para la búsqueda que hiciste.</p>
        <p class="shil">Te recomendamos:</p>
        <ul>
            <li><b>Ampliar la búsqueda cambiando los parámetros.</b> 
                Ejemplo: buscar en otro o todos los centros de atención.
            </li>
            <li>
                <b>Revisar que el paciente calce con la búsqueda.</b> 
                Ejemplo: paciente busca dosis o vacuna que no lo corresponde por calendario del Ministerio de Salud.
            </li>
            <li>
                <b>Para Vacunas COVID</b> volver a buscar más adelante, normalmente los cupos 
                se publican los viernes o a medida que nos llegan las vacunas desde el Ministerio de Salud.           
            </li>
        </ul>`
    },
    {
        "idAreas": [
            "e11c303e-309b-46d4-bc60-ab10011f6ac5", // Telemedicina
        ],
        mensaje: `
        <p class="hil">No encontramos citas para la búsqueda que hiciste.</p>
        <p class="shil">Te recomendamos:</p>
        <ul>
            <li><b>Ampliar la búsqueda cambiando los parámetros.</b> 
                Ejemplo: buscar por especialidad en vez de profesional; por un servicio general 
                en vez de un servicio específico.
            </li>
            <li>
                <b>Revisar que el paciente calce con la búsqueda.</b> Ejemplo: adulto buscando reservar 
                en pediatría, no muestra cupos.
            </li>
        </ul>`
    },
    {
        "idAreas": [
            "7822cd9e-4552-4cb8-8313-abd00148111c", // Banco de Sangre
        ],
        mensaje: `
        <p class="hil">No encontramos citas para la búsqueda que hiciste.</p>
        <p class="shil">Te recomendamos:</p>
        <ul>
            <li><b>Ampliar la búsqueda cambiando los parámetros.</b> 
                Ejemplo: buscar en otro o todos los centros de atención.
            </li>
            <li>
           <b>Para donar sangre:</b> se publican cupos con una vista de 14 días hacia futuro.
               
            </li>

        </ul>`
    },
]

export const MENSAJE_LISTA_ESPERA = {
    mensaje: `
        <p class="hil">No encontramos cupos para la búsqueda que hiciste.</p>
        <p class="shil">Te recomendamos:</p>
        <ul>
            <li><b>Ampliar la búsqueda cambiando los parámetros.</b> 
                Ejemplo: buscar por especialidad en vez de profesional; 
                por un servicio general en vez de un servicio específico;
                o buscar en otro o todos los centros de atención.
            </li>
            <li><b>Revisar que no tengas una reserva similar previamente agendada.</b> 
                Deberás anularla antes de reservar una nueva. Para hacerlo busca en tu correo 
                electrónico un email de Reservas UCCHRISTUS (no olvides revisar el spam), 
                o ve al Portal Paciente.
            </li>
            <li>
                <b>Revisar que el paciente calce con la búsqueda.</b> 
                Ejemplo: adulto buscando reservar en pediatría no muestra cupos.
            </li>
            <li>
                <b>Inscribirte a nuestra Lista de Espera.</b> 
                Te enviaremos un mail cuando el profesional se le libere un cupo.
            </li>
        </ul>`
}
