import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ENV } from 'src/environments/environment';

@Component({
  selector: 'app-error-reserva',
  templateUrl: './error-reserva.component.html',
  styleUrls: ['./error-reserva.component.scss']
})
export class ErrorReservaComponent implements OnInit {

  @Output() public dialogEvent: EventEmitter<any> = new EventEmitter();

  public message: string;
  public type: string;
  public stay: boolean;
  public aplicaSantander;
  public urlSantander;
  public ocultarNuevaBusqueda = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ErrorReservaComponent>,
  ) {
    this.message = data.message;
    this.type = data.type;
    this.stay = data.stay;
    const esPortalSantander = sessionStorage.getItem('portalOrigen') === 'santander'
    this.aplicaSantander = data.esMensajeSantander && data.tienePlanSantander && !esPortalSantander;
    this.urlSantander =  ENV.urlSitioSantander
  }

  ngOnInit() {
  }

  volver(bus) {

    this.dialogEvent.emit(bus);
    this.dialogRef.close();
  }
}
