import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { BusquedaComponent } from './busqueda/busqueda.component';
import { SeleccionComponent } from './seleccion/seleccion.component';
import { IdentificacionComponent } from './identificacion/identificacion.component';
import { ConfirmacionComponent } from './confirmacion/confirmacion.component';
import { UtilsService } from 'src/app/services/utils.service';
import { ENV } from 'src/environments/environment';
import gtag, { install } from 'ga-gtag';
import { ActivatedRoute, Router } from '@angular/router';
import { AgendaAmbulatoriaService } from 'src/app/services/agenda-ambulatoria.service';

install(ENV.analyticsCode);

declare var pushGTag: Function;

@Component({
  selector: 'app-reservacion',
  templateUrl: './reservacion.component.html',
  styleUrls: ['./reservacion.component.scss']
})
export class ReservacionComponent implements OnInit, AfterViewInit, OnDestroy {

  public curEtapa: number = 0;
  public busquedaInfo: any;
  public paciente: any;
  public calendario: any;
  public reservaRealizada: boolean = false;
  public readQuery: boolean = false;
  public reglasActuales: any = [];
  public mensajesActuales: any = [];
  public codCita: number;
  public valorConvenio: number;
  public emitterReloadBusqueda: any;
  public reloadNumber = 0;
  public saludIntegral = false;
  public checkSaludIntegral = false;
  public verMedicoAsociado = false;
  public datosBeneficiarioMedico;
  public rutMatch;
  public listaEsperaData = null;
  public confirmacionListaEsperaData;
  public confirmacionProcedimiento;
  public isProcedimiento = false;
  public buscarProfesionalRelacionadoSuscription;
  public resetReservaObj;
  public volverSaludIntegralSuscription;
  public removerDerivacion = false;
  public saludEspecialidad;
  public dataExamenesAdjunto;
  public reservaAnulada = false;
  public saludIntgralBotonVolver = {
    text: '',
    estado: ''
  };

  @ViewChild('tabGroup', { static: false }) tabGroup: any;
  @ViewChild('seleccion', { static: false }) seleccion: SeleccionComponent;
  @ViewChild('identificacion', { static: false }) identificacion: IdentificacionComponent;
  @ViewChild('confirmacion', { static: false }) confirmacion: ConfirmacionComponent;

  constructor(
    public utils: UtilsService,
    public aRouter: ActivatedRoute,
    public router: Router,
    public agendaService: AgendaAmbulatoriaService
  ) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.cambiarEtapa(0, true);

      this.resetReservaObj = this.utils.obsClearReserva().subscribe(r => {
        this.resetReserva();
      });

      this.seleccion.calendario.subscribe(data => {
        this.cambiarEtapa(2);
        this.calendario = data;
        this.listaEsperaData = null;
      })

      this.identificacion.datosPaciente.subscribe(data => {

        this.mensajesActuales = data.mensajes;
        this.paciente = data.paciente;
        this.valorConvenio = data.valorConvenio;
        this.calendario.cupo['idTipoCita'] = data['tipoCita'];
        this.calendario.cupo.centro['direccion'] = data['direccionCentro'];
        this.calendario.cupo['valorConvenio'] = data.valorConvenio;

        if (!this.busquedaInfo.documentoPaciente) {
          this.busquedaInfo.documentoPaciente = {
            tipoDocumento: data.busquedaPaciente.tipoDocumento,
            documento: data.busquedaPaciente.documento,
            documentoFormateado: data.busquedaPaciente.documentoFormateado
          }
        }

        if (data.reglas && data.reglas.length > 0) {
          this.reglasActuales = { reglas: data.reglas, reservable: data.reservable };
        }

        setTimeout(() => {
          this.utils.setEmitReservar();
        }, 500);


      });

      this.identificacion.confirmacionListaEspera.subscribe(data => {
        this.confirmacionListaEsperaData = data;
        this.reservaRealizada = true;
        this.cambiarEtapa(4);
      });

      this.identificacion.confirmacionProcedimiento.subscribe(data => {
        this.confirmacionProcedimiento = data;
        this.reservaRealizada = true;
        this.cambiarEtapa(4);
      });

      this.buscarProfesionalRelacionadoSuscription = this.utils.getBuscarProfesionalRelacionado().subscribe(data => {
        this.busquedaInfo = data;
        this.cambiarEtapa(1);
      });

      this.volverSaludIntegralSuscription = this.utils.saludIntegralVolver().getVolver().subscribe(res => {

        switch (res) {
          case 'VISTA_DERIVACION':
            this.saludIntgralBotonVolver.text = 'SALIR';
            break;
          case 'VISTA_CALENDARIO':
          case 'VISTA_AGENDA_PROFESIONAL':
            this.saludIntgralBotonVolver.text = 'VOLVER';
            break;
          case 'VISTA_DERIVACION_TITULAR':
            this.saludIntgralBotonVolver.text = 'VOLVER';
            break;
        }

        this.saludIntgralBotonVolver.estado = res;

      });

      this.saludEspecialidad = this.utils.especialidadDerivaciones().getEspecialidad().subscribe(data => {
        this.utils.showProgressBar()
        this.busquedaEmitter(data);
      });

    }, 100);

  }

  volverSaludIntegral() {

    switch (this.saludIntgralBotonVolver.estado) {
      case 'VISTA_DERIVACION':
        this.nuevaReserva()
        break;
      case 'VISTA_AGENDA_PROFESIONAL':
        this.utils.actionSaludIntegralVolver().setVolver('VISTA_AGENDA_PROFESIONAL')
        break;
      case 'VISTA_CALENDARIO':
        this.utils.actionSaludIntegralVolver().setVolver('VISTA_CALENDARIO');
        break;
      case 'VISTA_DERIVACION_TITULAR':
        this.utils.actionSaludIntegralVolver().setVolver('VISTA_DERIVACION_TITULAR')
        break;
    }
  }

  confirmarReserva(data) {
    if (data['response']) {
      this.dataExamenesAdjunto = {
        datosCita: data['data'],
        email: data['emailMedico'],
        idPaciente: data['idPaciente'],
        idMedico: data['idMedico'],
        documentoPaciente: this.busquedaInfo.documentoPaciente,
        tipoDocumento: this.busquedaInfo.tipoDocumento
      }
      this.reservaRealizada = true;
      this.listaEsperaData = null;
      this.codCita = data['data']['codCita'];
      this.cambiarEtapa(4);
    }
  }

  busquedaEmitter(data) {
    if (data && data.area && data.especialidad) {
      this.busquedaInfo = data;
      this.cambiarEtapa(1);
    }
  }

  anularhHora() {
    this.router.navigate(['/anular-reserva']);
    this.agendaService.crearMarca({
      dni: null,
      dni_type: null,
      element: 'BOTÓN ANULAR HORA',
      value: 'ANULAR HORA',
      detail: 'ANULAR HORA',
      source: 'PÁGINA PRINCIPAL'
    });

    gtag('event', 'Filtro de Búsqueda', { 'event_category': 'Anular Hora', 'event_label': `Anular`, 'value': '0' });


  }

  getParamsArea() {
    this.aRouter.queryParams.subscribe( params => {
      setTimeout(() => {
        if (params['portalOrigen']) {
          sessionStorage.setItem("portalOrigen", params['portalOrigen']);
        }else{
          sessionStorage.removeItem("portalOrigen");
        }
      },2000)
    })
    this.aRouter.params.subscribe(params => {
      if (params['area'] === 'saludintegral') {
        if (!ENV.activarSaludIntegral) {
          location.href = "/";
        }
        this.saludIntegral = true;
        setTimeout(() => {
          this.readQuery = true;
        }, 2000)
      }

      if (params['portal']) {
        sessionStorage.setItem("portalOrigen", params['portal']);
      } else {
        sessionStorage.removeItem("portalOrigen");
      }
      this.checkSaludIntegral = true;
    })

  }

  ngOnInit() {

    this.getParamsArea();
    this.emitterReloadBusqueda = this.utils.getReloadBusqueda().subscribe(r => {
      this.cambiarEtapa(1);
      this.reloadNumber = this.utils.aleatorio(1, 99999);
    });

  }

  irPortalPacientes() {
    window.location.href = "https://agenda.clinicasancarlos.cl/";
  }

  ngOnDestroy() {
    this.emitterReloadBusqueda.unsubscribe();
    this.buscarProfesionalRelacionadoSuscription.unsubscribe();
    this.resetReservaObj.unsubscribe();
  }

  cambiarEtapa(index: number, clearSession = false) {

    this.curEtapa = index;
    this.tabGroup.selectedIndex = this.curEtapa;

    if (index < 2) {
      this.listaEsperaData = null;
      this.isProcedimiento = false;
      this.calendario = null;
    }

    if (index === 0) {
      this.utils.resetPaciente();
      this.reinicioDatos()
    }

    window.scrollTo(0, 0);
    let etapa = "";
    switch (index) {
      case 0:
        etapa = this.saludIntegral ? "INGRESO SALUD INTEGRAL" : "BUSQUEDA DE ESPECIALIDADES";
        break;
      case 1:
        etapa = "SELECCIÓN";
        break;
      case 2:
        etapa = "IDENTIFICACIÓN";
        break;
      case 3:
        etapa = "MENSAJES DE REGLAS";
        break;
      case 4:
        etapa = "CONFIRMACIÓN";
        break;
      case 5:
        etapa = "ADJUNTAR EXÁMENES";
        break;
    }
    const documentoPaciente = this.busquedaInfo && this.busquedaInfo.documentoPaciente ? this.busquedaInfo.documentoPaciente : {};
    this.agendaService.crearMarca({
      dni: documentoPaciente.documento,
      dni_type: documentoPaciente.tipoDocumento,
      element: 'ETAPAS DE FLUJO',
      value: etapa,
      detail: 'SE REGISTRA ETAPA DE ' + etapa,
      source: etapa,
      into: "etapas"
    }, clearSession);

  }

  reinicioDatos() {
    this.busquedaInfo = null
    this.paciente = null;
    this.calendario = null
    this.reservaRealizada = null;
    this.listaEsperaData = null;
    this.confirmacionListaEsperaData = null;
    this.isProcedimiento = false;
    this.confirmacionProcedimiento = null;
    this.dataExamenesAdjunto = null;
    this.reservaAnulada = false;
  }

  nuevaReserva(fromBtn = false) {

    this.utils.reiniciarReserva();
    this.utils.resetPaciente();
    this.reinicioDatos()
    this.cambiarEtapa(0);

    if (this.saludIntegral && fromBtn) {
      this.utils.actionSaludIntegralVolver().setVolver('VISTA_INICIO');
      this.utils.actionNuevaHoraSaludIntegral().setNuevaHora(true);
    }

    this.utils.actionAdjuntarExamen().set(true);

    gtag('event', 'Reserva Exitosa', { 'event_category': 'Tomar otra hora', 'event_label': 'Nueva Hora', 'value': '0' });

    this.agendaService.crearMarca({
      dni: null,
      dni_type: null,
      element: 'BOTÓN TOMAR OTRA HORA',
      value: 'TOMAR OTRA HORA',
      detail: 'TOMAR OTRA HORA HORA',
      source: 'RESERVA EXITOSA'
    });

  }

  resetReserva() {
    setTimeout(() => {
      this.nuevaReserva();
    }, 1000)

  }

  readQuerySetter(event) {
    this.readQuery = event;
  }

  accionValidacionReglas(tipo: string) {
    switch (tipo) {

      case 'NUEVO':
        this.nuevaReserva();
        break;

      case 'VOLVER':
        this.reglasActuales = [];
        this.cambiarEtapa(2)
        break;

      case 'CONTINUAR':
        this.reglasActuales = [];
        this.cambiarEtapa(4)
        break;
    }
  }

  reservar() {
    this.utils.setEmitReservar()
  }

  setDatosBeneficiario(data) {

    this.datosBeneficiarioMedico = data;
    sessionStorage.setItem("datosBeneficiarioMedico", JSON.stringify(data));
    this.busquedaInfo = data;
    this.verMedicoAsociado = true;
    this.readQuery = false;
    this.cambiarEtapa(1);

    setTimeout(() => {
      this.readQuery = true;
      this.utils.hideProgressBar();
    }, 2000);

  }

  resetDatosBeneficiario() {
    const json = JSON.parse(sessionStorage.getItem("datosBeneficiarioMedico"));
    this.setDatosBeneficiario({ ...json, date: (new Date()).getTime() });
  }

  nuevaBusquedaCM() {

    this.utils.showProgressBar();
    this.readQuery = false;
    this.verMedicoAsociado = false;
    this.datosBeneficiarioMedico = null;
    this.rutMatch = null;
    setTimeout(() => {
      this.readQuery = true;
      this.utils.hideProgressBar();
    }, 1500);

  }

  listaEspera(data) {
    this.listaEsperaData = data;
    this.cambiarEtapa(2);
  }

  setProcedimiento() {
    this.isProcedimiento = true;
    this.cambiarEtapa(2);
  }

  mostarAdjuntarExamen() {
    this.cambiarEtapa(5);
  }


}
