import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from './services/utils.service';
import { MatDialog } from '@angular/material';
import { tap } from "rxjs/internal/operators";

@Injectable()
export class Interceptor implements HttpInterceptor {

  public reqClone: any;
  public clearProgBar: any;
  constructor(
    public utils: UtilsService,
    private dialogRef: MatDialog
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let show = false;
    if (
      !request.url.includes('/Servicios/Rel/Especialidades') &&
      !request.url.includes('/Profesionales?idArea') &&
      !request.url.includes('/Profesionales?codCanal') &&
      !request.url.includes('validarenrolamiento') &&
      !request.url.includes('Autenticar') &&
      !request.url.includes('Marcas') &&
      !request.url.includes('fromProfRel=true') && !request.url.includes('/CuposInmediatos') &&
      !request.url.includes('Areas') && !request.url.includes('Paises') && !request.url.includes('/Especialidades') &&
      !request.url.includes('/validarenrolamiento') &&
      !request.url.includes('/Perfil?rutProfesional') &&
      !request.url.includes('/Servicios?codCanal') &&
      !request.url.includes('/Servicios?idArea') &&
      !request.url.includes('fromSelCentros=true') &&
      !request.url.includes('almacenamiento/v1/archivosbase') &&
      !request.url.includes('usuarios/v1/sesiones/iniciar-sesion') &&
      !request.url.includes('ValidarRequiereOrden') &&
      !request.url.includes('CuposEspecCentro') &&
      !request.url.includes('CuposProfFechas') && 
      !request.url.includes('Pacientes?')) {
      setTimeout(() => {
        this.utils.showProgressBar();
        clearTimeout(this.clearProgBar)
      }, 200);
      show = true;
    } else {
      show = false;
    }
    request = request.clone(this.reqClone);

    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse && show) {
          this.clearProgBar = setTimeout(() => {
            this.utils.hideProgressBar();
          }, 3000);
        }
      }, error => {
        this.dialogRef.closeAll();
          const messageErr = `
          <ul>
            <li>Modificar los parámetros de búsqueda. Ejemplo: buscar por especialidad en vez de profesional; 
                por un servicio general en vez de un servicio específico, un centro distinto o más de uno, si la 
                búsqueda fue para todos los centros intenta nuevamente o busca por un solo centro.<br/><br/></li>
            <li>Revisar que no tengas una reserva similar previamente agendada. Deberás anularla antes de reservar una nueva. 
                Para hacerlo busca en tu correo electrónico un email de Reservas UCCHRISTUS (no olvides revisar el spam), 
                o ve al Portal Paciente.<br/><br/>
            </li>
            <li>
              Revisar que el paciente calce con la búsqueda. Ejemplo: adulto buscando reservar en pediatría no muestra cupos.
            </li>
          </ul>
        
        `

        if(
            !request.url.includes('AutorizaPagoCita') && 
            !request.url.includes('Marcas') && 
            !request.url.includes('upload/Url') &&
            !request.url.includes('ValidarRequiereOrden') &&
            !request.url.includes('ucchristus-adjuntos')){
          this.utils.mDialog("Te recomendamos", messageErr , "message", true);
        }

        setTimeout(() => {
          this.utils.hideProgressBar();
        }, 3000);

      })
    );

  }

}